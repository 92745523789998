import React from 'react';
import styles from './Initiatives.module.css';

const Initiatives = () => {
  return (
    <section className={styles.initiativesSection}>
      <h2 className={styles.initiativesTitle}>Our Initiatives</h2>
      <p className={styles.initiativesDescription}>
        Learn about our ongoing projects and campaigns for human rights.
      </p>
    </section>
  );
};

export default Initiatives;
