import React from 'react';
import styles from './About.module.css';

const About = () => {
  return (
    <section className={styles.aboutSection}>
      <h2 className={styles.aboutTitle}>About Us</h2>
      <p className={styles.aboutDescription}>
        Sanfir works globally to promote human rights and protect individual freedoms.
      </p>
    </section>
  );
};

export default About;
