import React from 'react';
import styles from './Resources.module.css';

const Resources = () => {
  return (
    <section className={styles.resourcesSection}>
      <h2 className={styles.resourcesTitle}>Resources</h2>
      <p className={styles.resourcesDescription}>
        Access educational materials and research reports on human rights topics.
      </p>
    </section>
  );
};

export default Resources;
