import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Navbar.module.css';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleDropdown = (menuItem) => {
    setActiveDropdown(activeDropdown === menuItem ? null : menuItem);
  };

  const menuItems = [
    {
      label: 'Home',
      link: '/',
      children: [
        'Introduction to SANFIR',
        'Mission and Vision statements',
        'Call to Action',
      ],
    },
    {
      label: 'About Us',
      link: '/about',
      children: [
        'History of SANFIR',
        'Governance Structure',
        'Meet the Team',
        'Contact Us',
      ],
    },
    {
      label: 'Our Work',
      link: '/our-work',
      children: [
        'Programs and Services',
        'Research and Publications',
        'Advocacy and Policy',
      ],
    },
    {
      label: 'Membership',
      link: '/membership',
      children: [
        'Benefits of Membership',
        'Membership Categories',
        'Membership Application Form',
        'Member Directory',
      ],
    },
    {
      label: 'Get Involved',
      link: '/get-involved',
      children: [
        'Volunteer Opportunities',
        'Donate',
        'Fundraising Campaigns',
        'Events Calendar',
      ],
    },
    {
      label: 'Resources',
      link: '/resources',
      children: [
        'Library',
        'FAQs',
        'Glossary',
        'Links',
      ],
    },
    {
      label: 'News and Blogs',
      link: '/news',
      children: [
        'Latest News',
        'Blog',
        'Newsletter Archive',
      ],
    },
    {
      label: 'Contact',
      link: '/contact',
      children: [
        'Contact Form',
        'Email',
        'Phone Number',
        'Physical Address',
        'Social Media Links',
      ],
    },
  ];

  return (
    <nav className={styles.navbar}>
      <h1>Sanfir</h1>
      <button className={styles.mobileMenuButton} onClick={toggleMobileMenu}>
        ☰
      </button>
      <ul className={`${styles.navLinks} ${isMobileMenuOpen ? styles.mobileMenuOpen : ''}`}>
        {menuItems.map((item, index) => (
          <li key={index} className={styles.navItem}>
            <div className={styles.dropdown}>
              <button
                className={styles.navLink}
                onClick={() => toggleDropdown(item.label)}
              >
                {item.label}
              </button>
              {activeDropdown === item.label && (
                <ul className={styles.dropdownMenu}>
                  {item.children.map((child, childIndex) => (
                    <li key={childIndex} className={styles.dropdownItem}>
                      <Link
                        to={`${item.link}/${child.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}`}
                        className={styles.dropdownLink}
                        onClick={() => {
                          toggleMobileMenu();
                          setActiveDropdown(null);
                        }}
                      >
                        {child}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
