import React from 'react';
import logo from '../assets/logo.png';  // Import logo image
import styles from './Home.module.css';  // Assuming you have styles for Home page

const Home = () => (
  <div className={styles.homeContainer}>
    <section className={styles.logoSection}>
      <img src={logo} alt="SANFIR Logo" className={styles.logo} />
    </section>
    <section>
      <h2>Introduction to SANFIR</h2>
      <p>
        Southern Africa Network for Immigrants and Refugees is a network of refugee-led 
        organizations (RLOs) that exists to amplify voices, protect and advocate for the 
        rights of refugees in the SADC region. We believe the complexity of migration 
        requires a flexible, collaborative response focused on achieving sustainable solutions.
      </p>
    </section>
    <section>
      <h2>Mission and Vision</h2>
      <h3>Our Mission</h3>
      <p>
        To protect and advocate for the rights of immigrants and refugees through 
        prevention, protection, capacity building, research, advocacy, and awareness-raising.
      </p>
      <h3>Our Vision</h3>
      <p>
        A Southern Africa where the rights and dignity of all immigrants and refugees 
        are recognized, respected, and upheld.
      </p>
    </section>
    <section>
      <h2>Call to Action</h2>
      <p>
        Join us in making a difference. Whether through volunteering, donating, or spreading 
        awareness, your support can help us achieve sustainable solutions and uphold the 
        rights of immigrants and refugees in Southern Africa. 
      </p>
      <button>Get Involved</button>
    </section>
  </div>
);

export default Home;
