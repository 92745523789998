import React from 'react';
import styles from './Contact.module.css';

const Contact = () => {
  return (
    <section className={styles.contactSection}>
      <h2 className={styles.contactTitle}>Contact Us</h2>
      <p className={styles.contactDescription}>
        Reach out for more information or to get involved with Sanfir.
      </p>
    </section>
  );
};

export default Contact;
